body,
html {
    margin: 0;
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #333;
    font-weight: 400;
    font-size: 14px;
    overflow: hidden;
}

a {
    color: #333;
    text-decoration: none;
    transition: all 0.3s;
}

a:focus,
a:hover {
    color: #2821fc;
}

.clearFix {
    zoom: 1;
}

.clearFix:after {
    content: "";
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}

.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-overflow-2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}

//S table自定义表格
.customTable {
    color: #332D42;
    &:before {
        content: none;
    }
    th {
        height: 60px;
        color: #332D42;
        font-weight: 400;
        font-size: 14px;
        &.el-table__cell {
            background-color: #f0f3fc;
        }
        &:first-child {
            &>.cell {
                padding-left: 120px;
            }
        }
    }
    th.el-table__cell.is-leaf {
        border-bottom: none;
    }
    td.el-table__cell {
        border-bottom: 4px solid #f7f8fc;
    }
    td {
        height: 80px;
        &:first-child {
            &>.cell {
                padding-left: 120px;
            }
        }
    }
    &.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background-color: #f7f8fc;
    }
    //&.el-table--enable-row-hover .el-table__body tr:hover {
    //  box-shadow: 0 5px 16px 0 rgba(4, 2, 49, 0.1);
    //}
}

// 商品列表表格
.deepBlueTable {
    overflow: hidden;
    color: #fff;
    &.el-table {
        background: transparent;
        overflow: hidden;
    }
    th {
        color: #fff;
        font-weight: normal;
        &.el-table__cell {
            background: rgba(75, 69, 255, 0.2);
        }
    }
    &.el-table td {
        &.el-table__cell,
        &.el-table th.el-table__cell.is-leaf {
            border-bottom: 3px dotted rgba(255, 255, 255, 0.1);
        }
    }
    &.el-table tr {
        background: transparent;
        .el-table_1_column_1 {
            border-top-left-radius: 9px;
        }
        .el-table_1_column_7 {
            border-top-right-radius: 9px;
        }
    }
    &.el-table--border::after,
    &.el-table--group::after,
    &.el-table::before {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
    }
    &.el-table td,
    &.el-table th.is-leaf {
        border: none;
    }
    &.deepBlueTable.el-table--border::after,
    &.deepBlueTable.el-table--group::after,
    &.deepBlueTable.el-table::before {
        border: none;
    }
    &.el-table--border::after,
    &.el-table--group::after,
    &.el-table::before {
        background: none;
    }
    &.el-table tbody tr:hover>td {
        background: unset !important;
    }
    .el-table__header tr,
    .el-table__header th {
        padding: 0;
        height: 0.6rem;
    }
    .el-table__body tr,
    .el-table__body td {
        padding: 0;
        height: 0.69rem;
    }
}

.el-link+.el-link {
    margin-left: 22px;
}

.input-number-left {
    .el-input__inner {
        text-align: left;
    }
}

// message-box绿色头部
.green-message-box {
    width: 500px;
    border: none;
    padding-bottom: 0;
    .el-message-box__header {
        // background: #2821fc;
        padding: 17px 24px 15px;
        span {
            color: #000;
            font-size: 16px;
            border-left: 5px solid #2821fc;
            padding-left: 10px;
            font-weight: 600;
        }
        i {
            color: #000;
            font-size: 16px;
        }
    }
    .el-message-box__content {
        height: 100px;
        box-sizing: border-box;
        //text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-message-box__container {
            display: flex;
            align-items: center;
            .el-message-box__status {
                position: relative;
                transform: none;
                top: 0;
                font-size: 16px!important;
                color: #EB3800;
            }
            .el-message-box__message {
                padding: 0;
                margin-left: 10px;
                font-size: 16px;
                color: #333;
            }
        }
    }
    .el-message-box__btns {
        text-align: center;
        padding: 0 0 30px;
        .el-button {
            padding: 11px 23px;
            font-size: 16px;
            &:last-child {
                margin-left: 30px;
            }
        }
    }
}

.script-message-box {
    width: 540px;
    border: none;
    padding-bottom: 0;
    .el-message-box__header {
        // background: #2821fc;
        padding: 17px 24px 15px;
        span {
            color: #000;
            font-size: 16px;
            border-left: 5px solid #2821fc;
            padding-left: 10px;
            font-weight: 600;
        }
        i {
            color: #000;
            font-size: 16px;
        }
    }
    .el-message-box__content {
        height: 100px;
        box-sizing: border-box;
        //text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-message-box__container {
            display: flex;
            align-items: center;
            .el-message-box__status {
                position: relative;
                transform: none;
                top: 0;
                font-size: 16px!important;
                color: #EB3800;
            }
            .el-message-box__message {
                padding: 0;
                margin-left: 10px;
                font-size: 16px;
                color: #333;
            }
        }
    }
    .el-message-box__btns {
        text-align: center;
        padding: 0 0 30px;
        .el-button {
            padding: 11px 23px;
            font-size: 16px;
            &:last-child {
                margin-left: 30px;
            }
        }
    }
}

// 验证输入框变红
.isError {
    .el-input__inner,
    .el-textarea__inner {
        border-color: #ff0000 !important;
    }
}

//自定义面包屑
.customBreadcrumb {
    .el-breadcrumb__inner,
    .el-breadcrumb__separator {
        color: #ababbb;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
        color: #5c5c6f;
    }
}

.input-number-left {
    .el-input__inner {
        text-align: left;
    }
}

.demo-ruleForm {
    .el-form-item__label {
        color: #1b162a;
    }
}

// tooltip文字提示
.draw_share_atooltip {
    background: #ffffff !important;
    box-shadow: 0px 0px 5px 0px rgba(4, 38, 142, 0.2);
    color: #5c5c6f !important;
}

.el-tooltip__popper[x-placement^="top"] .popper__arrow::after {
    border-top-color: #ffffff;
}

.el-tooltip__popper[x-placement^="top"] .popper__arrow {
    border-top-color: #ffffff;
}


//分页样式
.pages-center {
    text-align: center;
    background: #fff;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .el-pager {
        li {
            min-width: 21px;
            margin: 0 4px;
            line-height: 23px;
        }
       
        .btn-quicknext,
        .btn-quickprev {
            height: 21px !important;
        }
        .more::before {
            line-height: 21px;
        }
        .number {
            height: 21px;
            color: #333;
            font-size: 12px;
        }
        .active {
            background: #2821FC;
            color: #fff;
            border-radius: 50%;
        }
    }
}

.el-popup-parent--hidden {
    padding-right: 0 !important;
}

//面包屑
.genera-breadcrumb {
    .el-breadcrumb__item {
        &:last-child .el-breadcrumb__inner {
            color: #332D42;
        }
        .el-breadcrumb__inner {
            color: #A8A8B8;
        }
    }
}

.scro .el-scrollbar {
    .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
}

.steps-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    .step-item {
        width: 180px;
        margin-right: 12px;
        position: relative;
        .step-line {
            width: 130px;
            height: 14px;
            position: absolute;
            //right: 58px;
            left: -146px;
            top: 10px;
            background: url("../../assets/image/serviceData/xian.png") no-repeat;
            background-size: contain;
        }
        .step-text {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #DDDFEB;
            border-radius: 50%;
            color: #fff;
        }
        .step-title {
            margin-left: calc(-50% + 28px);
            margin-right: 50%;
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            color: #ABABBB;
        }
        &:first-child {
            .step-line {
                display: none;
            }
        }
        &:last-child {
            width: auto!important;
            margin-right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .step-title {
                white-space: nowrap;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.current {
            .step-text {
                background: #2821FC;
            }
            .step-line {
                background-image: url("../../assets/image/serviceData/blue.png");
            }
            .step-title {
                color: #060111;
            }
        }
    }
}

.custom-message {
    width: 520px;
    .el-message-box__title {
        font-weight: bold;
        color: #060111;
        font-size: 16px;
        &::before {
            display: inline-block;
            content: "";
            width: 4px;
            height: 12px;
            margin-right: 10px;
            background: #4B45FF;
        }
    }
    .el-message-box__content {
        padding: 60px 0;
        display: flex;
        justify-content: center;
        .el-message-box__status {
            font-size: 20px !important;
        }
        .el-message-box__message {
            font-size: 18px;
            color: #060111;
        }
    }
    .el-message-box__btns {
        display: flex;
        justify-content: center;
        .el-button {
            width: 76px;
            height: 40px;
            font-size: 14px;
        }
    }
}

//下拉框内里样式
.goods-popper {
    border: none;
    background: #0C0E3F !important;
    .el-select-dropdown__list {
        background: #0C0E3F;
        .el-select-dropdown__item {
            color: #fff;
            width: 380px;
            text-overflow: clip;
            white-space: normal;
            height: max-content;
            &:hover{
                background: #262963;
            }

            &.hover{
                background: #262963;
            }
        }
    }
}

.el-select-dropdown, 
.el-message{
    z-index: 999999999 !important;
}

.language-dropdown {
    background-color: #272964;
    border-radius: 4px;
    border: 1px solid #131558;
    color: #fff;
    .el-dropdown-menu__item {
        color: #fff;
        &:focus, &:not(.is-disabled):hover {
            color: #fff;
            background-color: #070932;
        }
    }
    .popper__arrow {
        border-bottom-color: #131558!important;
        &:after {
            border-bottom-color: #272964!important;
        }
    }
}