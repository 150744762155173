@font-face {
    font-family: 'iconfont';  /* Project id 2736943 */
    src: url('//at.alicdn.com/t/c/font_2736943_81zkn3ry3ak.woff2?t=1692673157895') format('woff2'),
    url('//at.alicdn.com/t/c/font_2736943_81zkn3ry3ak.woff?t=1692673157895') format('woff'),
    url('//at.alicdn.com/t/c/font_2736943_81zkn3ry3ak.ttf?t=1692673157895') format('truetype');
  }

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
